import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@services/auth/auth.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, OnDestroy {
  constructor(private _authService: AuthService) {}
  navigateToHome(e: Event): void {
    e.preventDefault();
    this._authService.routeToHome();
  }

  ngOnInit(): void {
    document.body.classList.add('footer_white-left');
    document.body.classList.add('footer_white-right');
  }

  ngOnDestroy(): void {
    document.body.classList.remove('footer_white-left');
    document.body.classList.remove('footer_white-right');
  }
}
